import currentStatusReportService from '@/business/currentStatusReportService'
import { filterRulesMapped } from '@/tools/filterRules'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { dateSortFunction } from '@/tools/functions'
import service from '@/middleware'

export default {
  name: 'HarvesterCurrentStatusDetailReport',
  components: {
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    mapData: [],
    mapConfiguration: { },
    loadingTable: true,
    middleware: service.http
  }),
  computed: {
    ...mapState('app', {
      entitySelectorItems: state => state.entitySelectorItems,
      selectedItem: state => state.selectedItem,
      entitySelectorConfiguration: state => state.entitySelectorConfiguration
    }),
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    if (this.selectedItem) {
      this.getData(this.selectedItem.harvesterId)
    }
  },
  beforeDestroy () {
    this.resetEntitySelector()
  },
  methods: {
    ...mapMutations('app', {
      resetEntitySelector: 'resetEntitySelector'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    refreshTable () {
      if (this.selectedItem) {
        this.getData(this.selectedItem.harvesterId, true)
      }
    },
    resize () {
    },
    getData (harvesterId, refresh = false) {
      this.loadingTable = true
      currentStatusReportService.getDetail(harvesterId, 'HARVESTER').then(response => {
        if (!refresh) {
          this.setTableFilterRules(response.headers)
          this.tableHeaders.cleanAndUpdate(response.headers)
          /*
          Para ordenar las columnas fecha y hora
          TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
          */
          this.tableHeaders.find(header => header.value === 'date').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY') }
          this.tableHeaders.find(header => header.value === 'time').sort = (a, b) => { return dateSortFunction(a, b, 'HH:mm:ss') }
        }
        this.tableData.cleanAndUpdate(response.data)
        this.mapData.cleanAndUpdate(response.data)
        this.mapConfiguration = { maps: response.maps }
        this.loadingTable = false
      })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    clickRow () {
    },
    dataChangeEventHandler (newData) {
      this.mapData.cleanAndUpdate(newData)
    }
  },
  watch: {
    selectedItem () {
      if (this.selectedItem) {
        this.getData(this.selectedItem.harvesterId)
      }
    }
  }
}
