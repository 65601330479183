import WorkedAreaTotalComponent from '@/components/drive-units/dashboard/workedAreaTotal/workedAreaTotalComponent.vue'
import WorkedAreaWorkFrontComponent from '@/components/drive-units/dashboard/workedAreaWorkFront/workedAreaWorkFrontComponent.vue'
import WorkedAreaFarmComponent from '@/components/drive-units/dashboard/workedAreaFarm/workedAreaFarmComponent.vue'
import CurrentStatesChartComponent from './current-states-chart/CurrentStatesChartComponent.vue'

export default {
  name: 'HarvestersDashboardComponent',
  components: {
    WorkedAreaTotalComponent,
    WorkedAreaWorkFrontComponent,
    WorkedAreaFarmComponent,
    CurrentStatesChartComponent
  },
  props: {
    chartClick: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  data: () => ({
    workedAreaSector: 'HARVEST'
  }),
  computed: {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  methods: {
  }
}
