import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import currentStatusReportService from '@/business/currentStatusReportService'
import thingApi from '@/api/thing.api'
import activityApi from '@/api/activity.api'
import { configurationService } from '@/business/configurationService'

// constante de update de gráficos (en minutos)
const REFRESH = 10

export default {
  name: 'CurrentStatesChartComponent',
  components: {
    DashboardChartContainer
  },
  props: {
    chartComponentId: {
      type: String,
      required: true
    },
    sector: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },
  data: () => ({
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [],
      selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: null,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    activities: [],
    types: [],
    currentChartId: 0,
    loading: false,
    chartName: '',
    intervalId: null
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    this.loading = true
    // constante para validar si cargar inicialmente los gráficos
    let initialLoad = true

    if (this.sector) {
      // actividades
      const activityResponse = await activityApi.getAllForSelector(this.sector)
      if (Array.isArray(activityResponse.data)) {
        this.chartContainerModel.filters[0].data.cleanAndUpdate(activityResponse.data)
      }

      // configuración
      initialLoad = await this.getConfiguration(initialLoad)

      this.loading = false
      // ejecuto la validación del selector, si es correcta, se carga el gráfico, sino abro el selector
      if (initialLoad) {
        // creo los gráficos
        this.getData(this.chartContainerModel)
      } else {
        this.chartContainerModel.showNavigationPanel = true
      }
    } else {
      this.loading = false
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  destroyed () {
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details () {
      const activitiesName = this.activities.map(a => a.name)
      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('dashboard.currentState')}</h3>`
      details += `<h5>${this.$t('activity')}: ${activitiesName}</h5>`
      return details
    }
  },
  methods: {
    async getData (filters, update = false) {
      clearInterval(this.intervalId)
      await this.getDashboardData(filters, update)
      const that = this
      that.intervalId = setInterval(async function () {
        await that.updateAllCharts()
      }, REFRESH * 60 * 1000)
    },
    async getDashboardData (filters, update) {
      this.loading = true

      // se guarda el estado de los filtros
      this.activities = filters.filters[0].selectedData
      // ids de actividadaes
      const activitiesId = this.activities.map(a => a.id)

      // request de tipos de cosas
      this.types = (await thingApi.getThingTypesByActivity(activitiesId, this.sector.key)).data

      const dashboardResponse = await currentStatusReportService.getChart(this.types, this.activities, this.sector)
      this.chartContainerModel.charts.cleanAndUpdate([dashboardResponse])
      // MODIFICACIÓN DE LOS FILTROS
      if (update) {
        this.chartContainerModel.update = true
        const that = this
        Vue.nextTick(function () {
          that.chartContainerModel.update = false
        })
      // PRIMERA CARGA DE LOS GRÁFICOS
      } else {
        this.chartName = this.chartContainerModel.charts[0].name
      }
      this.loading = false
    },
    /**
     * Este método es ejecutado cuando se actualizan todos los gráficos por el timer del "autoreporte"
     */
    async updateAllCharts () {
      this.loading = true

      // const typesKey = this.types.map(t => t.key)

      const dashboardResponse = await currentStatusReportService.getChart(this.types, this.activities, this.sector)
      this.chartContainerModel.charts.cleanAndUpdate([dashboardResponse])

      this.chartContainerModel.update = true
      const that = this
      Vue.nextTick(function () {
        that.chartContainerModel.update = false
      })

      this.loading = false
    },
    /**
     * Para validar el selector de filtros y habilitar/deshabilitar el botón para guardar
     */
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.chartContainerModel.filters[0].selectedData
        that.chartContainerModel.disableSaveButton =
        activitySelected.length === 0
      })
    },
    /**
     * Para obtener la configuración de filtros
     * Esta función se ejecuta en mounted
     * @param {*} init
     * @returns
     */
    async getConfiguration (init) {
      let initialLoad = init
      const config = await configurationService.get(this._id)
      if (config) {
        // actividades
        if (this.chartContainerModel.filters[0].data.length > 0) {
          if (config.data.activities && config.data.activities.length > 0) {
            config.data.activities.forEach(activity => {
              if (this.chartContainerModel.filters[0].data.find(a => a.id === activity.id)) {
                this.chartContainerModel.filters[0].selectedData.push(activity)
              }
            })
          } else {
            this.chartContainerModel.filters[0].selectedData.cleanAndUpdate([this.chartContainerModel.filters[0].data[0]])
          }
        } else {
          initialLoad = false
        }
      } else {
        if (this.chartContainerModel.filters[0].data.length > 0) {
          this.chartContainerModel.filters[0].selectedData.cleanAndUpdate([this.chartContainerModel.filters[0].data[0]])
        } else {
          initialLoad = false
        }
      }
      return initialLoad
    }
  },
  watch: {
    'chartContainerModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
