<template>
  <div id="view-container" class="harvester-current-status full-height">
    <div class="full-height">
      <v-tabs v-model="selectedTab" centered show-arrows hide-slider>
        <BreadcrumbComponent
          :tabs="tabs"
          :tabSelected="tabSelected.bind(this)" />
        <v-tab-item value="tab-dashboard">
          <div v-if="selectedTab === 'tab-dashboard'" class="tab-item-content">
            <HarvestersDashboardComponent
              ref="dashboard"
              :chartClick="dashboardChartClick.bind(this)" />
          </div>
        </v-tab-item>
        <v-tab-item value="tab-summary">
          <div class="full-height">
            <HarvesterCurrentStatusReport
              ref="summary"
              :tableRowClick="summaryReportRowClick.bind(this)"/>
          </div>
        </v-tab-item>
        <v-tab-item value="tab-detail">
          <div class="full-height ml-1 mr-3">
            <HarvesterCurrentStatusDetailReport
              ref="detail"/>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import i18n from '@/i18n'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import HarvestersDashboardComponent from '@/components/harvesters/dashboard/dashboard.vue'
import HarvesterCurrentStatusReport from '@/components/harvesters/reports/current-status-report/CurrentStatusReport.vue'
import HarvesterCurrentStatusDetailReport from '@/components/harvesters/reports/current-status-report/current-status-detail-report/CurrentStatusDetailReport.vue'

export default {
  name: 'HarvesterCurrentStatusReportView',
  props: {
  },
  components: {
    BreadcrumbComponent,
    HarvestersDashboardComponent,
    HarvesterCurrentStatusReport,
    HarvesterCurrentStatusDetailReport
  },
  data () {
    return {
      tabs: [
        {
          id: 'tab-dashboard',
          name: i18n.t('navbar.dashboard')
        },
        {
          id: 'tab-summary',
          name: i18n.t('harvesters.reports.currentStatus.title')
        }
      ],
      selectedTab: 'tab-summary'
    }
  },
  computed: {
  },
  created () {
  },
  mounted () {
    const meta = this.$route.meta
    if (meta.tab) this.selectedTab = meta.tab
  },
  methods: {
    ...mapMutations('app', {
      setEntitySelectorItems: 'setEntitySelectorItems',
      setEntityConfiguration: 'setEntityConfiguration',
      resetEntitySelector: 'resetEntitySelector',
      setSelectedItem: 'setSelectedItem'
    }),
    ...mapMutations('reportDefer', {
      commitVisible: 'commitVisible'
    }),
    tabSelected (id) {
      const summaryRef = this.$refs['summary']
      const detailRef = this.$refs['detail']
      if (this.$router.currentRoute.path === '/drive-units' && id === 'tab-summary') {
        this.$router.push({ path: 'drive-units/summary-report' })
      } else if (id === 'tab-summary' && summaryRef && detailRef) {
        this.commitVisible({ visible: false })
        detailRef.loadingSpinner = false
        summaryRef.loadingSpinner = true
        summaryRef.$refs['map'].$refs['mapTabbedComponentTableComponent'].change()
      } else if (id === 'tab-detail' && summaryRef && detailRef) {
        this.commitVisible({ visible: false })
        detailRef.loadingSpinner = true
        summaryRef.loadingSpinner = false
        detailRef.$refs['map-current-status-detail'].$refs['mapTabbedComponentTableComponent'].change()
      } else if (id === 'tab-dashboard') {
        this.commitVisible({ visible: false })
        if (summaryRef) summaryRef.loadingSpinner = false
        if (detailRef) detailRef.loadingSpinner = false
      }
      this.selectedTab = id
    },
    summaryReportRowClick (data) {
      this.setEntityConfiguration({ name: this.$t('harvesters.title'), value: 'harvesterId', text: 'harvesterName' })
      const items = []
      this.$refs.summary.tableData.forEach(harvester => {
        items.push({ harvesterId: harvester._id, harvesterName: harvester.name })
      })
      this.setEntitySelectorItems(items)
      this.setSelectedItem({ harvesterId: data._id, harvesterName: data.name })
      if (this.tabs.filter(tab => tab.id === 'tab-detail').length === 0) {
        this.tabs.push({
          id: 'tab-detail',
          name: i18n.t('detail')
        })
      }
      this.selectedTab = 'tab-detail'
    },
    dashboardChartClick () {
      this.selectedTab = 'tab-summary'
    }
  }
}
</script>

<style scoped>
  .harvester-current-status >>>.tab-item-content{
    height: calc(100% - 26px) !important;
  }
  .harvester-current-status >>>.v-tabs-items{
    height: calc(100% - 32px);
  }
</style>
